import React from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";

function Skills() {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#243949"
          fill-opacity="0.3"
          d="M0,288L48,277.3C96,267,192,245,288,218.7C384,192,480,160,576,128C672,96,768,64,864,85.3C960,107,1056,181,1152,213.3C1248,245,1344,235,1392,229.3L1440,224L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
        ></path>
      </svg>

      {/* skills header flex */}
      <div className="d-flex justify-content-around align-items-center skills-container">
        <h1 className="text-light">My Skills</h1>
        <FaLongArrowAltLeft className="left-arrow" />
      </div>

      {/* skills flex */}
      <div className="container-fluid skills-flex ">
        {/* left side */}
        <div className="skills-left-flex text-light ">
          <div>
            <h5 className=" me-2">Python</h5>
            <h5 className=" me-2">HTML</h5>
            <h5 className=" me-2">CSS</h5>
            <h5 className=" me-2">JavaScript</h5>
            <h5 className=" me-2">React Js</h5>
            <h5 className=" me-2">PHP</h5>
            <h5 className=" me-2">SQL</h5>
            <h5 className=" me-2">Java</h5>
            <h5 className=" me-2">C++</h5>
            <h5 className=" me-2">C</h5>
            <h5 className=" me-2">Node Js</h5>
            <h5 className=" me-2">Express Js</h5>
            <h5 className=" me-2">Django</h5>
            <h5 className=" me-2">Flutter</h5>
            <h5 className=" me-2">Dart</h5>
            <h5 className=" me-2">Adobe Photoshop</h5>
            <h5 className=" me-2">Latex</h5>
          </div>

          {/* progress bars */}
          <div className="mt-3">
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "95%" }}
                aria-valuenow={95}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                95%
              </div>
            </div>

            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "98%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                98%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "98%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                98%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "85%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "90%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "30%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                30%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "55%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                55%
              </div>
            </div>

            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "80%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                80%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "95%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                95%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "90%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "90%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "90%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "65%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                65%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "80%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                80%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "83%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                83%
              </div>
            </div>
            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "96%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                96%
              </div>
            </div>

            <div className="progress mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "96%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>
          </div>
        </div>

        {/* Right side */}
        <div className="skills-right-flex text-light">
          <div>
            <h5 className=" me-2">Bootstrap</h5>
            <h5 className=" me-2">GitHub</h5>
            <h5 className=" me-2">Git</h5>
            <h5 className=" me-2">MySQL</h5>
            <h5 className=" me-2">MongoDB</h5>
            <h5 className=" me-2">Blockchain</h5>
            <h5 className=" me-2">Ethereum</h5>
            <h5 className=" me-2">Solidity</h5>
            <h5 className=" me-2">Cyrptocurrency</h5>
            <h5 className=" me-2">Firebase</h5>
            <h5 className=" me-2">Postman</h5>
            <h5 className=" me-2">NLP</h5>
            <h5 className=" me-2">Office 365</h5>
            <h5 className=" me-2">Microsoft Office</h5>
            <h5 className=" me-2">WordPress</h5>
            <h5 className=" me-2">Tableau</h5>
            <h5 className=" me-2">MATLAB</h5>
          </div>

          {/* progress bars */}
          <div className="mt-3">
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "95%" }}
                aria-valuenow={95}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                95%
              </div>
            </div>

            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "90%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>

            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "90%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                90%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "75%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                75%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "70%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                70%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "40%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                40%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "40%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                40%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "40%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                40%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "40%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                40%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "85%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                85%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "80%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                80%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "86%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                86%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "98%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                98%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "92%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                92%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "70%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                70%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "78%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                78%
              </div>
            </div>
            <div className="progress  mb-3 progress-icons">
              <div
                className="progress-bar progressbg"
                role="progressbar"
                style={{ width: "70%" }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                70%
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
