import React from "react";
import axios from "axios";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      ContactEmail: "",
      message: "",
    };
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ ContactEmail: event.target.value });
  }

  onMsgChange(event) {
    this.setState({ message: event.target.value });
  }

  submitEmail(e) {
    e.preventDefault();
    axios({
      method: "POST",
      url: "https://my-portfolio-v5rn.onrender.com/send/mail",
      data: this.state,
    }).then((response) => {
      if (response.data.status === "success") {
        alert("Message Sent.");
        this.resetForm();
      } else if (response.data.status === "fail") {
        alert("Message failed to send.");
      }
      window.location.reload();
    });
    
  }

  resetForm() {
    this.setState({ name: "", ContactEmail: "", message: "" });
  }


  render() {
    return (
      <div className=" text-light container">
        <br />
       <hr />
       <br />
       <br />
       <br />
        <section className="container  w-75">
          <div class="container shadow-lg p-3 mb-5  rounded">
            <h1 className="container text-center">Contact me</h1>
            <form onSubmit={this.submitEmail.bind(this)} method="POST">
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control inputbg"
                  name="name"
                  onChange={this.onNameChange.bind(this)}
                  value={this.state.name}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div class="form-group">
                <label for="email">Email address</label>
                <input
                  type="email"
                  class="form-control inputbg"
                  name="ContactEmail"
                  placeholder="Enter your email"
                  required
                  value={this.state.contact_email}
                  onChange={this.onEmailChange.bind(this)}
                />
              </div>
              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  class="form-control inputbg"
                  name="message"
                  rows="3"
                  required
                  value={this.state.message}
                  onChange={this.onMsgChange.bind(this)}
                ></textarea>
              </div>
              <button class="btn btn-dark mt-4 btnbg text-center" type="submit">
                Submit
              </button>
            </form>
          </div>
        </section>
       


        
      </div>
    );
  }
}

export default Contact;
