import React from 'react';
import { Link } from 'react-router-dom';
import {FaLinkedin,FaFacebookSquare,FaTwitter,FaInstagram} from 'react-icons/fa'


function Footer() {
  return (
    <section class="customFooter" >

    {/* footer */}
    <footer class=" text-center text-dark">
    
      <div class="container p-4 pb-0">
       
        <section class="mb-4">
         
          <Link
            class="fs-1 text-light mx-2"
            to = "https://www.linkedin.com/in/okyere-amponsah-kwatia-141b761a4/" target='_blank'
            
            ><FaLinkedin />
          </Link>

          <Link
            class="fs-1 text-light mx-2"
            to = "https://web.facebook.com/okyere.amponsah.9/" target='_blank'
            
            ><FaFacebookSquare />
          </Link>

          <Link
            class="fs-1 text-light mx-2"
            to = "https://twitter.com/OriontyPrincip2" target='_blank'
            
            ><FaTwitter />
          </Link>

          <Link
            class="fs-1 text-light mx-2"
            to = "https://www.instagram.com/oriontyprinciples/?hl=en" target='_blank'
            
            ><FaInstagram />
          </Link>

        
        </section>
        
      </div>
    

     
      <div class="text-center p-3 container-fluid" style={{backgroundColor: "rgba(0, 0, 0, 0.2)",color:"white"}}>
        © 2023 Copyright : 
        <a href class="text-white text-decoration-none container" 
          > Orionty Portfolio Website || All Rights Reserved</a
        >
      </div>
     
    </footer>
    
</section>
  )
}

export default Footer
