import React from 'react'
// import Navbar from './Navbar'
import Background from './Background'
import About from './About'
import Skills from './Skills'
import MyProjects from './MyProjects'
import Contact from './Contact'
import Footer from './Footer'
// import { Link } from "react-router-dom";
import { Link, Element} from 'react-scroll';

function Home() {

  return (
    <div>
      {/* Navbar */}
        <section>
        <div className="container">
      <div class=" TopNav mt-4 container d-flex justify-content-around">
        <nav class="navbar navbar-expand-lg navbar-dark ">
          <Link class="navbar-brand" 
          activeClass="active"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          style={{cursor:"pointer"}}
          >
            ORIONTY
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <Link class="nav-link" 
                 activeClass="active"
                 to="home"
                 spy={false}
                 smooth={true}
                 offset={-70}
                 duration={500}
                 style={{cursor:"pointer"}}
                
                >
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" 
                 activeClass="active"
                 to="projects"
                 spy={false}
                 smooth={true}
                 offset={-70}
                 duration={500}
                 style={{cursor:"pointer"}}
                
                >
                  Projects
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" 
                 activeClass="active"
                 to="skills"
                 spy={false}
                 smooth={true}
                 offset={-70}
                 duration={500}
                 style={{cursor:"pointer"}}
                
                >
                  Skills
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link" 
                 activeClass="active"
                 to="about"
                 spy={false}
                 smooth={true}
                 offset={-70}
                 duration={500}
                 style={{cursor:"pointer"}}
                
                >
                  About me
                </Link>
              </li>
             
            </ul>
            <Link 
             activeClass="active"
             to="contact"
             spy={false}
             smooth={true}
             offset={-70}
             duration={500}
            >
            <button type="button" class="ms-5 btn btn-outline-info text-white  ml-2">
              Contact me
            </button>

            </Link>
          </div>
        </nav>
      </div>
    </div>

        </section>
        <br />
        <Element name="background">
        <Background />
      </Element>
        <br />
        <Element name="about">
        <About />
      </Element>
        <br />
        <Element name="skills">
        <Skills />
      </Element>
        <br />
        <Element name="projects">
        <MyProjects />
      </Element>
        <br />
        <Element name="contact">
        <Contact />
      </Element>
        <br />
        <Footer />
    
     </div>
  )
}

export default Home