import React from "react";
import {FaLongArrowAltRight} from 'react-icons/fa'
import { Link } from "react-scroll";

function About() {
  return (
    <div>
      <div className="about-dblock container">
        <div className="container about-child-flex1">
          <div className="about-circle"> </div>
          <div className="blob"> </div>
        </div>

       <div className="mt-5">
       <div className="  about-child-flex2 mt-5 pt-5 container-fluid">
          <h1 className="about-me about-copy">About me</h1>
          <FaLongArrowAltRight  className="right-arrow" />
          <p className="about-text">
            I am a dedicated data analyst, machine learner, and accomplished
            developer with a BSc in Physics (Computing) from Kwame Nkrumah
            University of Science and Technology (KNUST) in Ghana. With over six
            years of experience working on diverse web-based projects, I have
            honed my skills in developing innovative interfaces that prioritize
            accessibility, reachability, and security. My methodical approach
            allows me to excel under pressure, managing multiple deadlines
            concurrently. I actively stay abreast of technological advancements
            to continually refine best practices in web design. My abilities
            encompass both autonomous work and effective collaboration within a
            team, making me a versatile asset to any project.
          </p>
          <br />

          <Link 
           activeClass="active"
           to="contact"
           spy={true}
           smooth={true}
           offset={-70}
           duration={500}
          >
          
          <button type="button" className="btn contact-btn ">Contact me</button>
          </Link>
        </div>


       </div>

        <div></div>
      </div>
    </div>
  );
}

export default About;
