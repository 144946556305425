import React from "react";
// import { Link } from "react-router-dom";

function MyProjects() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#243949"
          fill-opacity="0.3"
          d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
        ></path>
      </svg>
      <br />
      <br />
      <div className="container text-center text-light mb-5 mt-3">
        <h3>My Projects</h3>
        <p className=" container text-center animation">Please contact me for more projects</p>

      </div>
      <div class="container shadow-lg pt-5">
  <div class="row">
    <div class="col ">
    <div class="card shadow-lg cardbg mb-5 " style={{width: "18rem"}}>
  <img src="/img/esurde ads.jpg" class="card-img-top img-rounded " alt="Esurde Learning Platform"  />
  <div class="card-body  text-light">
    <h5 class="card-title">Esurde</h5>
    <p class="card-text">Education Website</p>
    
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/toff.png" class="card-img-top img-rounded" alt="Church Management System" />
  <div class="card-body  text-light">
    <h5 class="card-title">Toff</h5>
    <p class="card-text">Church Management System</p>
   
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/cozy.jpg" class="card-img-top img-rounded" alt="Cozy Diner" />
  <div class="card-body  text-light">
    <h5 class="card-title">Cozy Diner</h5>
    <p class="card-text">Restaurant Mobile App</p>
   
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/inside.png" class="card-img-top img-rounded" alt="The Inside" />
  <div class="card-body  text-light">
    <h5 class="card-title">The Inside</h5>
    <p class="card-text">Restaurant Website</p>
   
  </div>
</div>
    </div>
  </div>
<br />
  {/* second row */}
  <div class="row">
    <div class="col">
    <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/mrc.jpeg" class="card-img-top img-rounded" alt="Esurde Learning Platform" />
  <div class="card-body  text-light">
    <h5 class="card-title">MRC</h5>
    <p class="card-text">Movie Recommendation Model</p>
    
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/translator.png" class="card-img-top img-rounded" alt="..." />
  <div class="card-body  text-light">
    <h5 class="card-title">Translator</h5>
    <p class="card-text">Language Translator Model</p>
   
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/vaults.png" class="card-img-top img-rounded" alt="..." />
  <div class="card-body  text-light">
    <h5 class="card-title">Gold Vaults</h5>
    <p class="card-text">Gold Vaults Website</p>
   
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/aus.png" class="card-img-top img-rounded" alt="..." />
  <div class="card-body  text-light">
    <h5 class="card-title">Aus Investment</h5>
    <p class="card-text">Bank Website & App</p>
   
  </div>
</div>
    </div>
  </div>
  <br />
{/* Third Row */}
<div class="row">
    <div class="col">
    <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/book.png" class="card-img-top img-rounded" alt="Book Recommendation Model" />
  <div class="card-body  text-light">
    <h5 class="card-title">Book Recommender</h5>
    <p class="card-text">Book Recommendation Model</p>
    
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/fraud.png" class="card-img-top img-rounded" alt="Online Payments Detection" />
  <div class="card-body  text-light">
    <h5 class="card-title">Fraud Detector</h5>
    <p class="card-text">Online Payments Detection</p>
   
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/movie.png" class="card-img-top img-rounded" alt="Movie Website" />
  <div class="card-body  text-light">
    <h5 class="card-title">HD Movies</h5>
    <p class="card-text">Movie Website</p>
   
  </div>
</div>
    </div>
    <div class="col">
         <div class="card shadow-lg cardbg mb-5  rounded-3" style={{width: "18rem"}}>
  <img src="/img/law-firm.png" class="card-img-top img-rounded"  alt="Law Firm Website & App" />
  <div class="card-body  text-light">
    <h5 class="card-title" >LF</h5>
    <p class="card-text">Law Firm Website & App</p>
   
  </div>
</div>
    </div>
  </div>









</div>
      
    </div>
  );
}

export default MyProjects;
