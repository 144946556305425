import React from 'react'
import {FaDownload,FaLinkedin,FaFacebookSquare,FaTwitter,FaInstagram} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import cv from '../pdf/okyere-amponsah-cv.pdf'


function Background() {
  return (
    <div>
        
        <div className='container divider text-light shadow-lg'>
            <div className='child-divider1 container p-5'>
            <h3 className='role-caption'>Full Stack Engineer</h3>
            <h1 className='orionty-text'>Okyere<br />Amponsah Kwatia</h1>
            <p className='caption'>I am a dedicated data analyst, machine learner, <br />deep learner, and accomplished developer</p>
           <Link to={cv} className='text-white'>
           <div className='cv text-center pt-1 fw-bold shadow-lg'>
              <FaDownload className='text-warning' /><br />
                CV
            </div>
           </Link>

            </div>


            <div className='child-divider2 container d-flex justify-content-around'>
            <img src='img/orionty.jpeg' alt='Orionty' className='orionty-img  shadow-sm' />
            <div className='column-icons  d-flex mt-4'>
              <Link to = "https://www.linkedin.com/in/okyere-amponsah-kwatia-141b761a4/" target='_blank'><FaLinkedin  className='fs-2 top-icon-margin text-light'/> </Link>
              <Link to = "https://web.facebook.com/okyere.amponsah.9/" target='_blank'><FaFacebookSquare  className='fs-2 top-icon-margin text-light'/> </Link>
              <Link to = "https://twitter.com/OriontyPrincip2" target='_blank'><FaTwitter  className='fs-2 top-icon-margin text-light'/> </Link>
              <Link to = "https://www.instagram.com/oriontyprinciples/?hl=en" target='_blank'><FaInstagram  className='fs-2 top-icon-margin text-light'/> </Link>
            </div>
            </div>

        </div>
        
        </div>
  )
}

export default Background